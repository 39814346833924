import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '@app/shared/shared.module';
import { DownloadButtonComponent } from './download-button.component';

@NgModule({
  imports: [
    SharedModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule
  ],
  declarations: [DownloadButtonComponent],
  exports: [DownloadButtonComponent]
})
export class DownloadButtonModule { }
