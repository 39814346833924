import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ButtonsDefaultModule } from '@app/shared/components/buttons/default/buttons-default.module';
import { DialogModule } from '@app/shared/components/dialog/dialog.module';
import { JadFormFieldsCheckboxModule } from '@app/shared/components/form-fields/checkbox/form-fields-checkbox.module';
import { LoadingBarModule } from '@app/shared/services/local/loading-bar/loading-bar.module';
import { ProjectsService } from '@app/shared/services/projects.service';
import { TracksService } from '@app/shared/services/tracks.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { AddToProjectDialogComponent } from './add-to-project-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatPaginatorModule,
    MatIconModule,
    MatTooltipModule,
    DialogModule,
    MatDialogModule,
    LoadingBarModule,
    ButtonsDefaultModule,
    JadFormFieldsCheckboxModule,
    MatDialogModule,
    MatProgressSpinnerModule
  ],
  declarations: [AddToProjectDialogComponent],
  exports: [AddToProjectDialogComponent],
  providers: [TracksService, ProjectsService]
})
export class AddToProjectDialogModule { }
