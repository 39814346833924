import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WaveformLoadingService } from '@app/shared/services/local/waveform-loading.service';
import { TrackVariationsService } from '@app/shared/services/trackvariations.service';
import { WaveformComponent } from './waveform.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [WaveformComponent],
  exports: [WaveformComponent],
  providers: [TrackVariationsService, WaveformLoadingService]
})
export class WaveformModule { }
